


























import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { Rules } from "../../../utils/veeValidate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  @Prop({ required: false, type: [Number, String], default: "" })
  private value!: number | string;

  @Prop({ required: false, type: String })
  private label?: string;

  @Prop({ required: true, type: String })
  private name!: string;

  @Prop({ required: false, type: String, default: "" })
  private hint!: string;

  @Prop({ required: false, type: [Object, String] })
  private rules?: Rules;

  @Prop({ required: false, type: String, default: () => null })
  private placeholder!: string | null;

  @Prop({ required: false, type: String, default: "text" })
  private type!: "number" | "password" | "text";

  @Prop({ required: false, type: Boolean, default: false })
  private autofocus!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private readonly!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private hideDetails!: boolean;

  @Prop({ required: false, type: String })
  private prependInnerIcon?: string;

  @Prop({ required: false, type: [Number, Boolean], default: false })
  private counter!: number | boolean;

  @Prop({ required: false, type: Number })
  private maxlength?: number;

  @Prop({ type: Boolean, default: false })
  private bigLabel?: boolean;

  @Prop({ type: String, default: () => null })
  private autocomplete?: string | null;
}
