







import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "../components/commons/layouts/LoginLayout.vue";
import NewPassword from "../components/auth/NewPassword.vue";

@Component({
  components: {
    LoginLayout,
    NewPassword,
  },
})
export default class NewPasswordPage extends Vue {}
